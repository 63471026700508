
import { defineComponent, ref, watch, onMounted, reactive, computed } from 'vue';
import { ElConfigProvider } from 'element-plus';
import ptBr from 'element-plus/lib/locale/lang/pt-br';
import useEmitter from '@/composables/Emmiter'
import { consultores, retornoFiltro } from '@/services/BusinessInteligence'
import { useStore } from 'vuex';
import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';

export default defineComponent({
    name: "filtro-finalizados",
    components: {
        FilterBase,
        ElConfigProvider,
    },
    setup() {
        const usuarioLogado = computed(() => store.getters.usuarioLogado);
        const store = useStore();
        const codNivel = store.getters.getCodNivel;
        const emitter = useEmitter();
        const loadingInput = ref(true)
        const activeModal = ref(false);
        const closeModal = ref(false);
        const disabledClick = ref(false);
        const showClose = ref(false)
        const optionsConcessionaria: any = ref([]);
        const optionsGrupo: any = ref([])
        const optionsMarca: any = ref([])
        const allMarcas = ref()
        const optionsConsultores: any = ref([])
        const consultoSelect = ref()
        const label = ref("Realize o filtro de acordo com suas preferências.");
        const showGoToBack = ref(false);
        const moment = require('moment');
        const dataAtual = moment().format('ddd MMM DD YYYY HH:mm:ss [GMT]ZZ')
        const currentDate = new Date();
        const allCodGrupo = ref()
        const startYear = 2019;
        const endYear = currentDate.getFullYear();
        type StringOrNull = string | null;
        const concessionariaResponsavel = ref()
        const estado = ref()
        const arrayEstado = ref<string>();
        const retornaEstado: any = ref([]);
        const retornaMarca: any = ref([]);
        const retornaGrupo: any = ref([]);
        const retonaConcessionaria: any = ref([]);
        const optionsFilter = ref();
        const ativa = ref(false);
        const dataFilter = reactive({
            userId: usuarioLogado.value.codUsuario,
            codGrupo: '-1' as StringOrNull,
            marca: '-1' as StringOrNull,
            codMarca: '-1' as StringOrNull,
            codConcessionaria: [],
            ano: dataAtual,
            mes: [],
            codEstado: '-1' as StringOrNull
        })

        async function ativaRequisicao() {
            loadingInput.value = true
            const usuario = codNivel == '-1' ? consultoSelect.value : usuarioLogado.value.codUsuario;
            const grupoReq = await retornoFiltro({
                userId: usuario == '-1' ? undefined : usuario,
                codEstado: dataFilter.codEstado == '-1' ? undefined : dataFilter.codEstado,
                codMarca: dataFilter.marca == '-1' ? undefined : dataFilter.marca,
                codGrupo: dataFilter.codGrupo == '-1' ? undefined : dataFilter.codGrupo
            })
            loadingInput.value = false
            return grupoReq
        }
        
        watch(() => ativa.value, async () =>{
            if(ativa.value){
                optionsFilter.value = await ativaRequisicao();
                getEstados(optionsFilter.value)
                getConcessionaria(optionsFilter.value)
                getGrupos1(optionsFilter.value)
                getMarcas1(optionsFilter.value)
                loadingInput.value = false
            }
            ativa.value = false;
        })
        
        watch(() => consultoSelect.value, async () => {
            ativa.value = true
            dataFilter.marca = '-1'
            dataFilter.codGrupo = '-1'
            dataFilter.codEstado = '-1'
            dataFilter.codConcessionaria = []
        })

        watch(() => dataFilter.codEstado, async () => {
            ativa.value = true
            dataFilter.marca = '-1'
            dataFilter.codGrupo = '-1'
            dataFilter.codConcessionaria = []
        })

        watch(() => dataFilter.codGrupo, async () => {
            ativa.value = true
            dataFilter.marca = '-1'
            dataFilter.codConcessionaria = []
        })

        watch(() => dataFilter.marca, async () => {
            ativa.value = true
            dataFilter.codConcessionaria = []
        })

        onMounted(async () => {
            consultoSelect.value = "-1"
            loadingInput.value = true
            if(codNivel == -1) activeModal.value = true     
            await Promise.all([getConsultores()])       
            loadingInput.value = false
            estado.value
            ativa.value = true
        })

        function getConcessionaria(optionsFilter) {
            const concessionariasNaoTratadas = optionsFilter.map(({codConcessionaria, nomeFantasia}) => {
                return {
                    codConcessionaria,
                    nomeFantasia
                }
            });
            retonaConcessionaria.value = concessionariasNaoTratadas.sort((a,b) => {
                if(a.nomeFantasia < b.nomeFantasia){
                    return -1
                }
                return 1
            })
        }

        function getGrupos1(optionsFilter) {
            const gruposNaoTratados = optionsFilter.map( ({nomeGrupo, codGrupo}) => {
                return {
                    nomeGrupo,
                    codGrupo
                }
            });

            const gruposUnicos =  gruposNaoTratados.reduce((acc, gruposArrayFiltrados) => {
                const existe = acc.some(grupo => grupo.codGrupo ==  gruposArrayFiltrados.codGrupo);
                if(gruposArrayFiltrados.codGrupo && !existe) {
                    const obj = {
                        nomeGrupo: gruposArrayFiltrados.nomeGrupo,
                        codGrupo: gruposArrayFiltrados.codGrupo
                    };
                    acc.push(obj);
                }

                return acc;
            },[]);

            retornaGrupo.value = gruposUnicos.sort((a,b) => {
                if(a.nomeGrupo < b.nomeGrupo){
                    return -1
                }
                return 1
            })
        }

        function getMarcas1(optionsFilter) {
            const marcasNaoTratadas = optionsFilter.map( ({Marca, codMarca}) => {
                return {
                    Marca,
                    codMarca
                }
            });
            
            const marcasUnicas = marcasNaoTratadas.reduce((acc, marcasArrayFiltrada) => {
                const existe = acc.some(marca => marca.codMarca == marcasArrayFiltrada.codMarca);
                if(marcasArrayFiltrada.codMarca && !existe){
                    const obj = {
                        marca: marcasArrayFiltrada.Marca,
                        codMarca: marcasArrayFiltrada.codMarca
                    };
                    acc.push(obj);
                }
                return acc;
            }, []);

            retornaMarca.value = marcasUnicas.sort((a,b) => {
                if(a.marca < b.marca){
                    return -1
                }
                return 1
            })
        }

        function getEstados(optionsFilter) {
            const estadosNaoTratados = optionsFilter.map( ({uf,estado}) => {
                return {
                    uf,
                    estado
                }
            });
            
            const estadosUnicos = estadosNaoTratados.reduce((acc, estadosArrayFiltrado) => {
                const existe = acc.some(estado => estado.estado == estadosArrayFiltrado.estado);
                if (estadosArrayFiltrado.estado && !existe) {
                    const obj = {
                        uf: estadosArrayFiltrado.uf,
                        estado: estadosArrayFiltrado.estado
                    };
                    acc.push(obj);
                }
                return acc;
            }, []);

            retornaEstado.value = estadosUnicos.sort((a,b) =>  {
                if(a.uf < b.uf){
                    return -1
                }
                return 1
            })
        }
        
        function disabledDate(time) {
            const year = new Date(time).getFullYear();
            return year < startYear || year > endYear;
        }

        async function emiteMudanca() {
            const anoFormatado = moment(dataFilter.ano).format('YYYY')
            const filterData = {
                ...dataFilter,
                codGrupo: dataFilter.codGrupo == '-1' ? undefined : dataFilter.codGrupo,
                codMarca: dataFilter.marca == '-1' ? undefined : dataFilter.marca,
                userId: codNivel == -1 ? consultoSelect.value == '-1' ? usuarioLogado.value.codUsuario : consultoSelect.value : usuarioLogado.value.codUsuario,
                ano: anoFormatado,
            }

            emitter.emit('filter-business-inteligence', filterData)
            emitter.emit('filter-business-allMarca-allGrupo-consultor', {
                allMarcas: allMarcas.value,
                allCodGrupo: allCodGrupo.value,
                allConsultor: optionsConsultores.value
            })

            closeModal.value = !closeModal.value;
            showGoToBack.value = false

            window.scroll({
                top: 0,
                behavior: 'smooth'
            })
        };

        async function getConsultores() {
            const response = await consultores()
            concessionariaResponsavel.value = response
            optionsConsultores.value = response
        }

        return {
            activeModal,
            closeModal,
            showClose,
            ptBr,
            emitter,
            emiteMudanca,
            optionsConcessionaria,
            label,
            showGoToBack,
            disabledClick,
            optionsGrupo,
            optionsMarca,
            dataFilter,
            endYear,
            consultoSelect,
            loadingInput,
            allMarcas,
            disabledDate,
            optionsConsultores,
            codNivel,
            arrayEstado,
            retornaEstado,
            retornaMarca,
            retornaGrupo,
            retonaConcessionaria
        };
    }
});
